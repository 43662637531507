// Module
var code = `<template>
  <require from="./register.scss"></require>
  <require from="../../../components/languages/languages"></require>
  <require from="../../../components/animated_checkmark/animated_checkmark"></require>
  <require from="../../../components/loader-ring/loader-ring"></require>
  <require from="../../../components/header-standard/header-standard"></require>
  <section id="register" class="flex-column">
    <header-standard show-back-button.bind="showBackButton" show-message.bind="showMessage" message.bind="message" show-logo.bind="showLogo" show-language.bind="showLanguage"></header-standard>    
    <div class="flex-grow"></div>
    <p id="create" t="auth.register.register5"></p>
    <form id="register_form" class="inputs column" submit.delegate="register()">
      <div>
        <input id="name" value.bind="name"  t="[placeholder]auth.name"/>
        <i></i>
      </div>
      <div>
        <input id="email" type="email" value.bind="email" t="[placeholder]auth.email_adresse"/>
        <i></i>
      </div>
      <div>
        <input ref="inputPsw" id="password" type="password" value.bind="password" i18n="[placeholder]auth.password"/>
        <i click.delegate="showPassword()" if.bind="inputPsw.type === 'text'" class="fas fa-eye"></i>
        <i click.delegate="showPassword()" if.bind="inputPsw.type === 'password'" class="fas fa-eye-slash"></i>
      </div>
    </form>
    <div class="flex-grow"></div>
    <div class="error flex-column" class.bind="displayError ? 'shake' : ''" >
      <span if.bind="displayError"> \${errorMessage}</span>
    </div>
    <div class="flex-grow"></div>
    <button class="btn btn-primary" type="submit" form="register_form"
      disabled.bind="loading || !(name && email && password) || invitedMessage" id="confirmRegisterButton" click.delegate="register()">
      <span i18n="auth.register.register4" if.bind="!loading"></span>
      <loader-ring class="loader-btn" if.bind="loading"></loader-ring>
    </button>
    <div id="connection" class="secondary flex-row">
      <span i18n="auth.register.register1"></span>
      <a click.delegate="toConnexion()" i18n="auth.register.register2"></a>
    </div>
    <div class="spacer-default"></div>
  </section>
</template>

`;
// Exports
export default code;