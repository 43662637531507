import { autoinject, computedFrom, inject } from "aurelia-framework";
import { AuthService } from "aurelia-auth";
import { Router } from "aurelia-router";
import { I18N } from "aurelia-i18n";
import { SingletonService } from "../../../singleton";
import { throws } from "assert";
import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { checkResponseStatus } from "http_clients/checkResponseStatus";

@autoinject
export class SignUp {
  private authService: AuthService;
  private email: string;
  private name: string;
  private password: string;
  private errorMessage: string;
  private phone: number;
  private displayError: boolean;
  private router: Router;
  private loading: boolean = false;
  private invitedMessage: boolean = false;
  private inputPsw: HTMLInputElement;
  private success: boolean = false;
  private disabledForBeta: boolean = true;

  showBackButton = true;
  showMessage= true;
  message = this.i18n.tr("auth.register.title");
  showLogo = false;
  showLanguage = false;


  constructor(
    authService: AuthService,
    router: Router,
    private i18n: I18N,
    private singleton: SingletonService,
    private userHttpClient: UsersHttpClients
  ) {
    this.authService = authService;
    this.router = router;
  }

  /* Register process */

  register() {
    this.displayError = false;
    this.loading = true;
      this.authService.signup(this.name, this.email, this.password).catch(error => {
        console.log(error);
        this.handleErrors(error);
      });
  }

  //Error Handling

  async handleErrors(error) {
    let errorFromBackend = await error.json();
    if (errorFromBackend.errors.msg[0].msg == "NOT_INVITED") {
      this.errorMessage = this.i18n.tr("auth.register.register_errorInvalid");
      this.invitedMessage = true;
    }
    else if (errorFromBackend.errors.msg == "EMAIL_ALREADY_EXISTS") {
      this.errorMessage = this.i18n.tr("auth.register.register_errorIncorrect");
    }
    else if (errorFromBackend.errors.msg[0].msg == "ALREADY_REGISTERED") {
      this.errorMessage = this.i18n.tr("auth.register.register_errorAlreadyRegistered");
    }
    else if (errorFromBackend.errors.msg[0].msg == "EMAIL_IS_NOT_VALID") {
      this.errorMessage = this.i18n.tr("home.error.welcome_error_not_an_email");
    }
    else if (errorFromBackend.errors.msg[0].msg == "PASSWORD_TOO_SHORT_MIN_5") {
      this.errorMessage = this.i18n.tr("home.error.register_errorBadpsw");
    }
    this.displayError = true;
    this.loading = false;
  }

  toConnexion() {
    this.router.navigateToRoute("login");
  }

  showPassword() {
    this.inputPsw.type === "password"
      ? (this.inputPsw.type = "text")
      : (this.inputPsw.type = "password");
  }
}
