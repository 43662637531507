import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-dependency-injection';
import { DialogController } from 'aurelia-dialog';


@autoinject
export class PleaseVerify {
    constructor(private dialogController: DialogController, private router: Router) { 
    }
}
