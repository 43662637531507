import { autoinject, computedFrom, inject } from "aurelia-framework";
import { AuthService } from "aurelia-auth";
import { Router } from "aurelia-router";
import { I18N } from "aurelia-i18n";
import { SingletonService } from "singleton";
import { default as environment } from "../../../../config/environment.json";

@autoinject
export class login {
  private email = "";
  private password = "";
  private locale: string = "fr";
  private displayError = true;
  private errorMessage: string;
  private router;
  private show: boolean;
  private inputPsw: HTMLInputElement;
  private focusHide: boolean = false;
  private loading: boolean = false;
  private shaking: boolean;
  private codePin: string = "empty";

  message = "Login";
  showBackButton = false;
  showMessage = false;
  showLogo = true;
  showLanguage = true;



  constructor(
    private authService: AuthService,
    router: Router,
    private i18n: I18N,
    private singleton: SingletonService
  ) {
    this.router = router;
    try {
      this.email = this.singleton.getEmail();
    } catch (e) {
      console.log(e);
    }
  }

  /* User connection part with authservice */
  login() {
    this.loading = true;
    this.displayError = false;
    this.errorMessage = "";
    this.singleton.setEmail(this.email);
    return this.authService
      .login(this.email, this.password)
      .then((response) => {
        this.singleton.setMe(response.user);
      })
      .catch((err) => {
        this.displayError = true;
        this.errorMessage =  this.i18n.tr("home.error.error_global")
        /* Error handling*/
        if (err.status == 422) this.errorMessage = this.i18n.tr("home.error.error_invalid");
        this.loading = false;
        if (err.status == 404) this.errorMessage =  this.i18n.tr("home.error.error_incorrect")
        this.loading = false;
        if (err.status == 409) this.errorMessage = this.i18n.tr("home.error.error_badpsw");
        this.loading = false;
        if(err.msg == "BLOCKED_USER") this.errorMessage = this.i18n.tr("home.error.error_blocked");
        this.loading = false;
      });
  }

  async shakeError() {
    this.shaking = true;
    setTimeout(() => {
      this.shaking = false;
    }, 200);
  }

  toForgotPsw() {
    this.router.navigateToRoute("forgot");
  }
  goToRegister() {
    this.router.navigateToRoute("register");
  }

  showPassword() {
    this.inputPsw.type === "password"
      ? (this.inputPsw.type = "text")
      : (this.inputPsw.type = "password");
  }

  goToPin() {
    this.singleton.setEmail(this.email);
    this.router.navigateToRoute("pin_code");
  }
  
  goToSignin() {
    this.router.navigateToRoute("signin");
  }

  async authenticate(name) {
    try {
      if (name === "google") {
        await this.authService.authenticate(name, false, null)
        
      } else {
        this.redirectToTwitter();
      }
    } catch (err) {
      console.log(err);
      err = await err.json();
      console.log(err);
      if (err.code == 401002) {
        this.displayError = true;
        this.errorMessage = this.i18n.tr("auth.signin.error.use_pswd");
      }
      else {
        this.displayError = true;
        this.errorMessage = this.i18n.tr("auth.signin.error.social");
      }
    }
  }

  redirectToTwitter() {
    const redirect_uri = `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=d0lxZWNUSzhESjJDNTVWdXZIeUc6MTpjaQ&redirect_uri=${environment.front_front}/auth/signin/intermediate&scope=users.read%20offline.access%20tweet.read&state=state&code_challenge=challenge&code_challenge_method=plain&state=state`;
    window.location.href = redirect_uri;
  }
}
