// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./please_verify.scss"></require>
  <section id="please_verify" class="flex-column">
    <div id="header" class="flex-row">
      <img id="thumbnail" click.delegate="dialogController.ok()" src="${___HTML_LOADER_IMPORT_0___}">
    </div>
    <div class="container flex-column">
      <h4 t="please_verify.needed_to_access">YOU NEED TO BE VERIFIED TO ACCESS CONTACTS</h4>
      <div class="text_container flex-row hidden_on_mobile">
        <span t="please_verify.verify_message_part_1">To verify your e-mail address, you can go</span>
        <a t="please_verify.verify_message_link" href="/profile" click.delegate="dialogController.ok()">here</a>
        <span t="please_verify.verify_message_part_2">or click on the link below</span>
      </div>
      <a t="please_verify.verify_bottom_link" href="/verify_mail" click.delegate="dialogController.ok()">Verify your mail</a>
    </div>
  </section>
</template>
`;
// Exports
export default code;