// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/brands/google.png", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/brands/twitter-x.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/img/hide.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/img/show.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("/static/img/padlock.png", import.meta.url);
// Module
var code = `<template>
  <require from="./login.scss"></require>
  <require from="../../../components/loader-ring/loader-ring"></require>
  <require from="../../../components/divider/divider"></require>
  <require from="../../../components/header-standard/header-standard"></require>
  <require from="../../../components/languages/languages"></require>
  <require from="../../../components/animated_checkmark/animated_checkmark"></require>

  <section id="login" class="flex-column">
    <header-standard show-back-button.bind="showBackButton" show-message.bind="showMessage" message.bind="message" show-logo.bind="showLogo" show-language.bind="showLanguage"></header-standard>    
    <!-- <img id="logo" src="/static/img/signin_icon.svg" /> -->
    <div class="flex-grow"></div>  
    <h2 t="auth.signin.socials_title"></h2>
    <div class="flex-grow" if.bind="displayError"></div>
    <button class="btn btn-socials flex-row google" click.delegate="authenticate('google')">
      <div class="flex-grow"></div>
      <img class="social_logo" src="${___HTML_LOADER_IMPORT_0___}">
      <p t="auth.signin.socials_google">Login with Google</p>
      <div class="flex-grow"></div>
    </button>
    <button class="btn btn-socials flex-row twitter-x" click.delegate="authenticate('twitter')">
      <div class="flex-grow"></div>
      <img class="social_logo" src="${___HTML_LOADER_IMPORT_1___}">
      <p t="auth.signin.socials_x">Login with 𝕏</p>
      <div class="flex-grow"></div>
    </button>
  
    <divider></divider>

    <form class.bind="shaking ? 'shake' : ''" id="loginform" class="inputs flex-column" submit.delegate="login()">
      <div>
        <input id="email" type="email" value.bind="email" t="[placeholder]auth.email_adresse" required />
      </div>
      <div class="password">
        <input ref="inputPsw" id="password" type="password" value.bind="password" i18n="[placeholder]auth.password"
          required />
        <img click.delegate="showPassword()" if.bind="inputPsw.type === 'text'" class="eye"
          src="${___HTML_LOADER_IMPORT_2___}"></i>
        <img click.delegate="showPassword()" if.bind="inputPsw.type === 'password'" src="${___HTML_LOADER_IMPORT_3___}"
          class="eye"></i>
      </div>
    </form>
    <p id="forgotpassword" class="flex-column" if.bind="!focusHide">
      <a click.delegate="toForgotPsw()" if.bind="!focusHide" t="auth.login.reset_my_password"></a>
    </p>
    <div class="error" class.bind="displayError ? 'shake' : ''" if.bind="displayError">
      <span> \${errorMessage} </span>
    </div>

    <div class="pin flex-column" click.delegate="goToPin()" if.bind="email.includes('@') && email.includes('.')">
      <img src="${___HTML_LOADER_IMPORT_4___}" />
    </div>
    
    <div class="flex-grow"></div>
    <button class="btn btn-primary" type="submit" form="loginform" disabled.bind="loading || !(email && password)">
      <span t="auth.login.enter" if.bind="!loading"></span>
      <loader-ring class="loader-btn" if.bind="loading"></loader-ring>
    </button>
    <div class="spacer-default" if.bind="focusHide"></div>
    <div class="secondary flex-row" if.bind="!focusHide">
      <p class="gray" t="auth.login.not_a_member"></p>
      <a href="register" t="auth.login.signup"></a>
    </div>
    <p class="error" if.bind="displayError">\${errorMessage}</p>
    <div class="flex-grow"></div>
  </section>
</template>
`;
// Exports
export default code;